import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { S1ClientConnectionStatus } from '@doc-abode/data-models';
import { useMemo, useState } from 'react';
import { capitalizeFirstLetter } from '../../../../../helpers';
import { useGetS1ClientConnectionStatuses } from '../../queries/useGetS1ClientConnectionStatuses';

const hoursToMinutes = (hours: number): number => hours * 60;

interface IConnectedClientsSortedBy {
    key: string;
    direction: string;
}

enum FilterOptions {
    ALL = 'all',
    DISCONNECTED = 'disconnected',
    WARNING = 'warning',
    CONNECTED = 'connected',
}

export const useConnectedClientsTableViewModel = () => {
    const [filterByStatus, setFilterByStatus] = useState<FilterOptions>(FilterOptions.ALL);
    const [sortConfig, setSortConfig] = useState<IConnectedClientsSortedBy>({
        key: 'lastActivity',
        direction: 'descending',
    });
    const [displayIntervalMinutes, setDisplayIntervalMinutes] = useState<number>(
        hoursToMinutes(24),
    );
    const {
        isLoading,
        isError,
        isSuccess,
        data: s1ClientConnectionStatuses,
        error,
        fetchStatus,
    } = useGetS1ClientConnectionStatuses(displayIntervalMinutes);

    const renderFilterOption: ItemRenderer<FilterOptions> = (
        option,
        { handleClick, handleFocus },
    ) => {
        return (
            <MenuItem
                key={option}
                text={capitalizeFirstLetter(option)}
                roleStructure="listoption"
                onClick={handleClick}
                onFocus={handleFocus}
            />
        );
    };
    const FilterSelect = () => {
        return (
            <Select<FilterOptions>
                onItemSelect={setFilterByStatus}
                items={Object.values(FilterOptions)}
                itemRenderer={renderFilterOption}
                filterable={false}
            >
                <Button
                    text={capitalizeFirstLetter(filterByStatus)}
                    rightIcon="double-caret-vertical"
                    placeholder="Filter by status"
                />
            </Select>
        );
    };

    const getClassNamesFor = (name: string) => {
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    const filteredS1ClientConnectionStatuses = useMemo(() => {
        if (filterByStatus === 'all') return s1ClientConnectionStatuses;
        return s1ClientConnectionStatuses?.filter((status) => status.status === filterByStatus);
    }, [filterByStatus, s1ClientConnectionStatuses]);

    const requestSort = (key: string) => {
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            setSortConfig({ key, direction: 'descending' });
        } else if (sortConfig && sortConfig.key === key && sortConfig.direction === 'descending') {
            setSortConfig({ key, direction: '' });
        } else {
            setSortConfig({ key, direction: 'ascending' });
        }
    };

    const sortedS1ClientConnectionStatuses = useMemo(() => {
        if (!filteredS1ClientConnectionStatuses) return [];
        return sortArrayByKey<S1ClientConnectionStatus>(
            filteredS1ClientConnectionStatuses,
            sortConfig.key as keyof S1ClientConnectionStatus,
            sortConfig.direction,
        );
    }, [filteredS1ClientConnectionStatuses, sortConfig.key, sortConfig.direction]);

    return {
        isLoading,
        isError,
        error,
        isSuccess,
        s1ClientConnectionStatuses: sortedS1ClientConnectionStatuses,
        fetchStatus,
        filterByStatus,
        displayIntervalMinutes,
        setFilterByStatus,
        requestSort,
        getClassNamesFor,
        FilterSelect,
        setDisplayIntervalMinutes,
    };
};

/**
 * Returns a sorted array of objects of type T by the key provided in the direction provided
 */
const sortArrayByKey = <T extends Record<string, string | null>>(
    array: T[],
    key: keyof T,
    direction: string,
) => {
    // Creating a copy of the array to avoid mutating the original array
    // and using the Array.prototype.slice.call method to convert an array-like object to an array
    // which is more performant than using either the Array.from or the concat methods
    return Array.prototype.slice.call(array).sort((a, b) => {
        if (a[key] === null) {
            return 1;
        }

        if (b[key] === null) {
            return -1;
        }
        if (a[key] === b[key]) {
            return 0;
        }
        if (a[key] !== null && b[key] !== null && a[key] !== undefined && b[key] !== undefined) {
            if (direction === '') {
                return NaN;
            }
            if (a[key].toLowerCase() < b[key].toLowerCase()) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key].toLowerCase() > b[key].toLowerCase()) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        } else {
            if (direction === '') {
                return NaN;
            }
            if (a[key] < b[key]) {
                return direction === 'ascending' ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return direction === 'ascending' ? 1 : -1;
            }
            return 0;
        }
    });
};
