import { baseUrl, retryable, rootUrl } from './baseApi';
export const getConfig = (authToken: string): Promise<any> =>
    retryable(() =>
        fetch(`${baseUrl}/config`, {
            headers: {
                Authorization: authToken,
            },
        }),
    );

export const getClientKeys = (authToken: string): Promise<any> =>
    retryable(() =>
        fetch(`${rootUrl}/config/client-keys`, {
            headers: {
                Authorization: authToken,
            },
        }),
    );
